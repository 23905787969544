// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  AccessTokenData,
  AuthTokenData,
  User,
} from '~/@types/auth';
import {
  ParamProfileBasicInfo,
  ParamSubscription,
  ParamUpdateUserEmail,
} from '~/@types/myAccount';

interface StateInterface {
  user: null;
}

interface AuthState {
  accessTokenData: AccessTokenData | undefined;
  authTokenData: AuthTokenData | null;
  isLoggedIn: boolean;
  user: User | null;
}

/**
 * States
 */
export const state = (): StateInterface => ({
  user: null,
});

/**
 * Actions
 */
export const actions: ActionTree<StateInterface, any> = {
  // region User Info
  async GET_USER_UPDATES({ commit, state }): Promise<any> {
    try {
      const response = await this.$api.get('/my-account/get-user-data-updates');

      if (response?.data) {
        await commit('myAccount/setUser', response.data, { root: true });

        if (process.client) {
          window.hj('identify', response.data.id, {
            email: response.data.email,
            name: response.data.name,
            role: response.data.role_name,
          });

          await this.$auth.setUser(response.data);
        }
      }

      return response;
    } catch (error) {
      this.$alert.show(
        error.message || 'Sorry, an error occurred while getting user updates',
        'error',
      );
      throw error;
    }
  },
  async GET_USER_ACCOUNT_META_DATA({ commit }): Promise<any> {
    try {
      const response = await this.$api.get('/my-account/get-user-account-meta-data');

      if (response?.data) {
        commit('SetUserMetaData', response.data);
      }

      return response;
    } catch (error) {
      this.$alert.show(
        error.message || 'Sorry! error occurred while getting user data. (error: 5002)',
        'error',
      );
      throw error;
    }
  },
  async UPDATE_PROFILE_BASIC_INFO(
    { dispatch },
    profileBasicInfo: ParamProfileBasicInfo,
  ): Promise<any> {
    try {
      const response = await this.$api.post(
        '/my-account/update_profile_basic_info',
        profileBasicInfo,
      );

      if (response?.data) {
        dispatch('GET_USER_UPDATES');
      }

      return response;
    } catch (error) {
      let errorMessage = 'Sorry! error occurred while saving your info. (error: 5003)';

      if (error.response?.status === 422) {
        errorMessage = 'Please check your data is correct and try again';
      }

      this.$alert.show(
        error.message || errorMessage,
        'error',
      );

      throw error;
    }
  },

  async cancelSubscription(
    { commit, state, dispatch },
    subscription: ParamSubscription,
  ): Promise<any> {
    // @ts-ignore
    const response = await this.$api.post(
      `/students/${subscription.user_id}/subscriptions/${subscription.id}/cancel`,
    );
    if (response?.code === 200 && response.data) {
      dispatch('GET_USER_UPDATES');
    } else if (response?.code === 422) {
      // @ts-ignore
      this.$alert.show(
        response.data.data || 'Please check your data is correct and try again',
        'error',
      );
    } else {
      // @ts-ignore
      this.$alert.show(
        response.data.data ||
        'Sorry! error occurred while saving your info. (error: 5003)',
        'error',
      );
    }
    return Promise.resolve(response);
  },

  UPDATE_USER_EMAIL(
    { commit, state, dispatch },
    emailUpdatingData: ParamUpdateUserEmail,
  ): any {
    return new Promise((resolve) => {
      // @ts-ignore
      this.$api
        .post('/my-account/update-user-email', emailUpdatingData)
        .then((response: any) => {
          if (response?.code === 200) {
            // @ts-ignore
            this.$auth.logout();
          } else if (response?.code === 401) {
            // @ts-ignore
            this.$alert.show('Your password is incorrect!', 'error');
          } else {
            // @ts-ignore
            this.$alert.show(
              response.data ||
              'Sorry! error occurred while saving your phone number. (error: 5006)',
              'error',
            );
          }
          resolve(response);
        });
    });
  },
  // endregion
};

/**
 * Mutations
 */
export const mutations: MutationTree<AuthState> = {
  setAccessToken(state, accessTokenData) {
    state.accessTokenData = accessTokenData;
  },
  setIsLoggedIn(state, isLoggedIn: boolean) {
    state.isLoggedIn = isLoggedIn;
  },
  async setUser(state, user: User | null) {
    state.user = user;
    console.log('user', user);
    
    // Set isLoggedIn false if user property is not available (because user properties wrapped with if(isLoggedIn)
    if (!user || !user.email) {
      state.isLoggedIn = false;
    }

    if (process.server) {
      return;
    }
    await (this.$cookies as any).set('auth-user', user, {
      path: '/',
      maxAge: 31536000,
    });
  },
};

/**
 * Getters
 */
export const getters: GetterTree<StateInterface, any> = {
  // isLoggedIn (state): boolean {
  //   return state.isLoggedIn;
  // },
  user(state): User | null {
    return state.user;
  },
};
